import { TimeOut } from "../configs/base";
import Core from "@sonphat/core";
import AuthApi from "../bootstrap/AuthApi";

const SP = window.SP;
const $Core = new Core({
    // Cấu hình xác thực truy cập, bắt buộc có nếu muốn tải tải trình xác thực
    // Auth: new AuthApi,
    // Auth: {
    //     Keycloak: SP["keycloak"] || {
    //         URL: "https://dangnhap.sbus.vn/auth",
    //         Realm: "Sbus.vn",
    //         ClientId: "LenhDienTu"
    //     },
    // },

    // cấu hình cho api
    Api: {
        BaseURL: SP["API_Default_BaseUrl"] || "https://localhost:5001/",

        Timeout: TimeOut, // (ms), 5s === 5000 ms

        NoExceptionHttpStatus: false,
        Custom: (cfg) => {
            if (cfg.headers) {
                let authApi = new AuthApi
                cfg.headers["Authorization"] = `Bearer ${authApi.Token}`
            }
            return cfg
        }
    },
    Helper: {
        // cấu hình helper
        Format: {
            // cấu hình default format, $Core.Helper.Format.Datetime(new Date(), format)
            DatetimeDefaultFormat: `{yyyy}/{mm}/{dd} {hh}:{ii}:{ss}.{ms}`
        }
    }
});
$Core.AuthApi = new AuthApi;

Object.defineProperty($Core.Api, "LenhVanChuyen", {
    get() {
        var self = this,
            args = arguments;
        args;
        return function () {
            return self.Make.apply(self, arguments).Config(
                c => (
                    (c.baseURL =
                        window.SP["API_BaseUrl"] ||
                        "https://localhost:5003/"),
                    c
                )
            );
        };
    }
});

Object.defineProperty($Core.Api, "LenhMobile", {
    get() {
        var self = this,
            args = arguments;
        args;
        return function () {
            return self.Make.apply(self, arguments).Config(
                c => (
                    (c.baseURL =
                        window.SP["API_BaseUrl_DieuDo"] ||
                        "https://localhost:5003/"),
                    c
                )
            );
        };
    }
});
Object.defineProperty($Core.Api, "Map", {
    get() {
        var self = this,
            args = arguments;
        args;
        return function () {
            return self.Make.apply(self, arguments).Config(
                c => (
                    (c.baseURL =
                        window.SP["API_Map"] ||
                        "https://localhost:5003/"),
                    c
                )
            );
        };
    }
});

// const app = new AppClass();

// (async function() {
//     var $app = await app.Ready();
//     if ($app) {
//         $app.setup({
//             eval: window.eval
//         });
//     }
// })();

// Object.defineProperty($Core, "App", {
//     get() {
//         return app;
//     }
// });

export default $Core;
